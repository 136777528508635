@import 'src/scss/_vars.scss';
@import 'src/scss/_mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500');
.personal-data-agreement {

    .visible{
        -webkit-transition: margin 1s;
        transition: margin 1s;
        margin-bottom: 0;
    }

    left: 50%;
    transform: translateX(-50%);

    font-family: 'Montserrat';
    margin: 0 auto;
    max-width: 1110px;
    box-shadow: 6.9px 5.8px 18px 0 rgba(9, 40, 71, 0.45);
    //padding: 0 20px;

    @extend %outer !optional;

    position: fixed;
    width: 100%;
    bottom: 0;
    margin-bottom: -500px;
    background-color: $main-background;
    z-index: 1000;
    //box-shadow: 0 10px 25px black;

    &.visible {
        transition: margin 1s;
        margin-bottom: 0;
    }

    .wrapper {
        @include flex(row, sb, c);
        @extend %wrapper !optional;

        @include screen(992) {
            @include flex(col, fs, c);
        }

        //padding: 20px 0 30px;
        padding: 20px 20px 30px 20px;
    }

    .text { 
        width: 64%;

        @include screen(992) {
            width: 100%;
        }
    }

    .buttons {
        @include flex(row, fe, fe);
        width: 35%;
        padding-top: 20px;
        
        @include screen(992) {
            justify-content: center;
            width: 100%;
        }

        @include screen(768) {
            @include flex(col, fs, c);
            width: 100%;
        }
    }

    .btn {

        &:last-child {
            margin-left: 10px;

            @include screen(768) {
                margin: 20px 0;
            }
        }

        &.cancel {
            color: black;
            background-color: transparent;
            border: 1px solid black;
            transition: all .3s;

            &:hover {
                box-shadow: inset 0 0 5px 0px grey;
            }
        }
    }

    .button {
        width: 180px;
        height: 40px;
        min-height: 40px;
        font-size: 14px;
        font-weight: 600;
        color: #f2f2f2;
        background-color: $footer-fill;
        border: 1px solid $footer-fill;
        //border-width: 0;
        border-radius: 25px;
        cursor: pointer;
        -webkit-transition: -webkit-filter .3s;
        transition: -webkit-filter .3s;
        transition: filter .3s;
        transition: filter .3s, -webkit-filter .3s;
    }
}
