@import 'src/scss/_vars.scss';
@import 'src/scss/personal-data-agreement.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500');

.body {
    max-width: 1170px;
    margin: 30px auto;
    background-color: $main-text-color;

    @media screen and (max-width: 39.9375em) {
        margin: 10px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        margin: 10px 0;
    }
}

.head {
    max-width: 1110px;
    margin: 0 auto 25px;
    border-top-left-radius: 20px;
    box-shadow: 6.9px 5.8px 18px 0 rgba(9, 40, 71, 0.45);
    background : {
        image: url('/images/header.png');
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 30px;
    box-sizing: border-box;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 10px;
        flex-direction: column;
        // align-items: center;
        border-top-left-radius: 10px;
        padding: 10px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        margin-bottom: 18px;
        padding: 16px 21px;
    }
}

.head__toolbar_for_little {
    display: none;

    @media screen and (max-width: 39.9375em) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.head__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.head__picture {
    background : {
        image: url('/images/logo.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 143px;
    height: 103px;
    margin-right: 17px;

    @media screen and (max-width: 39.9375em) {
        width: 100px;
        height: 72px;
        margin-right: 5px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 99px;
        height: 72px;
        margin-right: 8px;
    }
}

.head__text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: $main-text-color;
    max-width: 321px;

    @media screen and (max-width: 1024px) {
        font-size: 10px;
        max-width: 220px;
    }
}

.head__text_bold {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 11px;

    @media screen and (max-width: 39.9375em) {
        font-size: 12px;
        margin-bottom: 8px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 12px;
    }
}

.head__elements {
    max-width: 520px;
    width: 100%;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        max-width: 397px;
    }
}

.head__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.head__phonenumbers {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    color: $main-text-color;
    align-self: flex-end;
    text-align: right;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        font-size: 10px;
    }
}

.head__phonenumber {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: $main-text-color;
    text-decoration: none;
    display: block;
    margin-top: 5px;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        font-size: 12px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.head__toolbar {
    margin-bottom: 14px;
}

.head__accessibility {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 10px;
    color: $main-text-color;
    width: 196px;
    min-height: 24px;
    padding-right: 30px;
    border: 1px solid $main-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    background : {
        color: transparent;
        image: url('/images/accessibility.jpg');
        size: 30px 100%;
        position: right center;
        repeat: no-repeat;
    }
    margin-bottom: 16px;
    cursor: pointer;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        font-size: 8px;
        min-height: 17px;
        width: 165px;
        padding-right: 21px;
        padding-left: 0;
        margin-bottom: 11px;
        background-size: 25px 100%;
    }
}

.head__outer-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        justify-content: flex-start;
    }
}

.head__twitter, .head__vk {
    background : {
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 24px;
    height: 24px;
    opacity: 0.65;
    margin-left: 17px;

    @media screen and (max-width: 39.9375em) {
        margin-left: 0;
        margin-right: 20px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 17px;
        height: 15px;
        margin-left: 12px;
    }

    &:hover {
        opacity: 1;
    }
}

.head__twitter {
    background-image: url('/images/twitter.svg');
}

.head__vk {
    background-image: url('/images/vk.svg');
}

.head__searchbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 10px;
    }
}

.head__search-query {
    border: none;
    background-color: transparent;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: $main-text-color;
    text-align: right;
    padding: 0 10px;
    display: block;
    width: 170px;
    border-bottom: 1px solid $main-text-color;
    margin-right: 5px;

    @media screen and (max-width: 39.9375em) {
        width: 157px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 143px;
    }
}

.head__search-submit {
    background : {
        color: transparent;
        image: url('/images/magnifier.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 14px;
    height: 14px;
    border: none;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 9px;
        height: 10px;
    }
}

.main-grid {
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and(max-width: 1024px) and (orientation: landscape) {
        justify-content: center;

        > * {
            margin: 0 10px;
        }
    }
}

.main-grid__half {
    max-width: 543px;
    width: 100%;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        max-width: 376px;
    }
}

.main-grid__cells-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.main-grid__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 260px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: $main-text-color;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 6.9px 5.8px 18px 0 rgba(9, 40, 71, 0.45);
    background : {
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    margin-bottom: 24px;

    @media screen and (max-width: 39.9375em) {
        width: 145px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 180px;
        margin-bottom: 15px;
    }

    &:hover {
        box-shadow: 6.9px 5.8px 18px 0 rgba(9, 40, 71, 0.7);
    }

    &:active {
        box-shadow: none;
    }
}

.main-grid__cell_type_services {
    border-top-left-radius: 20px;
    height: 156px;
    background-image: url('/images/services.png');
    font-size: 22px;
    padding: 0 0 4px 15px;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 70px;
        background-image: url('/images/services-min.png');
        font-size: 16px;
        padding: 0 10px 2px 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 110px;
        font-size: 18px;
        padding: 0 0 0 20px;
    }
}

.main-grid__cell_type_applications {
    height: 66px;
    background-image: url('/images/applications.png');
    font-size: 16px;

    @media screen and (max-width: 39.9375em) {
        height: 30px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 47px;
        font-size: 14px;
    }
}

.main-grid__cell_type_expertise {
    height: 66px;
    background-image: url('/images/expertise.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 60px;

    @media screen and (max-width: 39.9375em) {
        height: 30px;
        font-size: 14px;
        justify-content: center;
        padding-left: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 47px;
        font-size: 14px;
    }
}

.main-grid__cell_type_attestation {
    height: 66px;
    background-image: url('/images/attestation.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 60px;

    @media screen and (max-width: 39.9375em) {
        height: 30px;
        font-size: 14px;
        justify-content: center;
        padding-left: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 47px;
        font-size: 14px;
    }
}

.main-grid__cell_type_documents {
    border-bottom-right-radius: 20px;
    height: 66px;
    background-image: url('/images/documents.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 60px;

    @media screen and (max-width: 39.9375em) {
        border-bottom-right-radius: 10px;
        height: 30px;
        font-size: 14px;
        justify-content: center;
        padding-left: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 47px;
        font-size: 14px;
    }
}

.main-grid__cell_type_news {
    border-top-left-radius: 20px;
    height: 242px;
    background-image: url('/images/news.png');
    font-size: 22px;
    padding: 0 0 80px 6px;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 60px;
        background-image: url('/images/news-min.png');
        font-size: 16px;
        padding: 0 18px 0 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 169px;
        font-size: 18px;
        padding: 0 0 56px 16px;
    }
}

.main-grid__cell_type_articles {
    height: 109px;
    background-image: url('/images/articles.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 60px;

    @media screen and (max-width: 39.9375em) {
        height: 60px;
        width: 68px;
        background-image: url('/images/articles-min.png');
        font-size: 14px;
        justify-content: center;
        padding-left: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 76px;
        font-size: 16px;
    }
}

.main-grid__cell_type_newspaper {
    height: 109px;
    border-bottom-right-radius: 20px;
    background-image: url('/images/newspaper.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 60px;

    @media screen and (max-width: 39.9375em) {
        height: 60px;
        width: 68px;
        border-bottom-right-radius: 10px;
        background-image: url('/images/newspaper-min.png');
        font-size: 14px;
        justify-content: center;
        padding-left: 0;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 76px;
        font-size: 16px;
    }
}

@media screen and (max-width: 39.9375em) {
    .main-grid__grid-piece_for_flex {
        display: flex;

        > *:first-child {
            margin-right: 10px;
        }
    }
}

.main-grid__cell_type_courses {
    border-top-left-radius: 20px;
    height: 156px;
    background-image: url('/images/courses.png');
    font-size: 22px;
    padding: 0 0 4px 48px;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 50px;
        background-image: url('/images/courses-min.png');
        font-size: 14px;
        padding: 0 0 0 8px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 110px;
        font-size: 18px;
        padding: 0 0 2px 48px;
    }
}

.main-grid__cell_type_timetable-free {
    height: 156px;
    background-image: url('/images/timetable-free.png');
    font-size: 16px;
    padding-left: 28%;
    justify-content: flex-start;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 50px;
        font-size: 12px;
        padding: 0;
        justify-content: center;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 110px;
        font-size: 14px;
        padding-left: 14%;
    }
}

.main-grid__cell_type_distance-learning {
    height: 156px;
    background-image: url('/images/distance-learning.png');
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 28%;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 50px;
        font-size: 12px;
        padding: 0;
        justify-content: center;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 110px;
        font-size: 14px;
        padding-left: 14%;
    }
}

.main-grid__cell_type_timetable {
    height: 156px;
    border-bottom-right-radius: 20px;
    background-image: url('/images/timetable.png');
    font-size: 16px;
    padding-left: 28%;
    justify-content: flex-start;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 50px;
        font-size: 12px;
        padding: 0;
        justify-content: center;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 110px;
        font-size: 14px;
        padding-left: 14%;
    }
}

.main-grid__cell_type_information {
    border-top-left-radius: 20px;
    width: 100%;
    height: 65px;
    background-image: url('/images/information.png');
    font-size: 15px;
    padding-left: 58px;

    @media screen and (max-width: 39.9375em) {
        border-top-left-radius: 10px;
        height: 50px;
        background-image: url('/images/information-min.png');
        font-size: 14px;
        padding-left: 0;
        padding-right: 8px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        height: 45px;
        font-size: 16px;
        padding-left: 6px;
    }
}

@media screen and (max-width: 1024px) {
    .main-grid__cell-text_for_big {
        display: none;
    }
}

.main-grid__cell-text_for_little {
    display: none;

    @media screen and (max-width: 1024px) {
        display: inline;
    }
}

.main-grid__cell_type_partners {
    width: 120px;
    height: 65px;
    background-image: url('/images/partners.png');
    font-size: 16px;

    @media screen and (max-width: 39.9375em) {
        width: 145px;
        height: 30px;
        background-image: url('/images/partners-min.png');
        font-size: 14px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 83px;
        height: 47px;
        font-size: 12px;
    }
}

.main-grid__cell_type_contacts {
    width: 120px;
    height: 65px;
    background-image: url('/images/contacts.png');
    font-size: 16px;

    @media screen and (max-width: 39.9375em) {
        width: 145px;
        height: 30px;
        background-image: url('/images/contacts-min.png');
        font-size: 14px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 83px;
        height: 47px;
        font-size: 12px;
    }
}

.main-grid__cell_type_requisites {
    width: 120px;
    height: 65px;
    background-image: url('/images/requisites.png');
    font-size: 16px;

    @media screen and (max-width: 39.9375em) {
        width: 145px;
        height: 30px;
        background-image: url('/images/requisites-min.png');
        font-size: 14px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 83px;
        height: 47px;
        font-size: 12px;
    }
}

.main-grid__cell_type_surveys {
    width: 120px;
    height: 65px;
    border-bottom-right-radius: 20px;
    background-image: url('/images/surveys.png');
    font-size: 16px;

    @media screen and (max-width: 39.9375em) {
        width: 145px;
        height: 30px;
        border-bottom-right-radius: 10px;
        background-image: url('/images/surveys-min.png');
        font-size: 14px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 83px;
        height: 47px;
        font-size: 12px;
    }
}

.foot {
    background-color: $footer-background;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 1110px;
    margin: 0 auto;
}

.foot__elements {
    display: flex;
    flex-wrap: wrap;
    // align-items: flex-start;
}

.foot__icon {
    display: block;
    width: 100px;
    height: 111px;
    background-color: $footer-fill;
    border-top-left-radius: 20px;
    background : {
        size: 53px;
        position: center;
        repeat: no-repeat;
    }
    text-decoration: none;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 70px;
        height: 89px;
        background-size: 37px;
    }
}

.foot__icon_type_address {
    background : {
        image: url('/images/map.svg');
    }
}

.foot__icon_type_contacts {
    background : {
        image: url('/images/telephone.svg');
    }
}

.foot__icon_type_feedback {
    background : {
        image: url('/images/feedback.svg');
    }
}

.foot__icon_type_statistics {
    background : {
        image: url('/images/statistics.svg');
    }
}

.foot__information {
    box-sizing: border-box;
    padding: 15px 11px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    color: $main-text-color;
    text-decoration: none;
    width: 180px;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        padding: 4px 11px;
        width: unset;
    }
}

.foot__information_type_phone {
    font-size: 13px;
}

.foot__phone-number {
    color: $main-text-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.foot__information_type_feedback {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        font-size: 18px;
    }
}

.foot__information_type_statistics {
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.foot__feedback {
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 50%;
    }
}

.foot__menu-checkbox {
    display: none;

    &:checked ~ .foot__navigation {
        display: block;
    }
}

.foot__menu-toggler {
    display: none;

    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 30px;
        color: $main-text-color;
        text-transform: uppercase;
        background-color: $footer-fill;
        cursor: pointer;

        &:after {
            content: '';
            background : {
                image: url('/images/foot__menu-toggler.png');
                size: contain;
                position: right center;
                repeat: no-repeat;
            }
            width: 14px;
            height: 8px;
            margin-left: 10px;
        }
    }
}

.foot__navigation {
    box-sizing: border-box;
    padding-top: 30px;
    background-color: $footer-fill;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.foot__nav-block {
    margin-left: 30px;
    width: 246px;

    @media screen and (max-width: 1024px) {
        padding-bottom: 20px;
    }
}

.foot__h {
    display: block;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    color: $main-text-color;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 24px;

    &:hover {
        color: #9bc1eb;
    }
}

.foot__menu {
    padding: 0;
    margin: 0;
    list-style-type: none;
    padding-left: 4px;
}

.foot__link {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    color: $main-text-color;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: #9bc1eb;
    }
}

.foot__link_type_sitemap {
    display: block;
    box-sizing: border-box;
    text-align: right;
    padding: 0 150px 24px 0;
    background-color: $footer-fill;
    border-bottom-right-radius: 20px;

    @media screen and (max-width: 1024px) {
        text-align: center;
        padding: 0 0 24px 0;
        font-size: 20px;
    }
}

.main {
    max-width: 1110px;
    margin: 0 auto 30px;
    display: flex;
    box-shadow: 0 9px 18px 0 rgba(9, 40, 71, 0.45);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.main__aside {
    background : {
        color: $footer-fill;
        image: url('/images/newspaper-icon.svg');
        size: 40px;
        position: center 24px;
        repeat: no-repeat;
    }
    width: 65px;
    border-top-left-radius: 20px;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.main__wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: $main-background;
    border-bottom-right-radius: 20px;

    @media screen and (max-width: 39.9375em) {
        padding: 10px;
        border-top-left-radius: 20px;
    }
}

.main__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
}

.main__breadcrumbs {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: $path-color;
    margin: 0;
    padding: 0;
    margin-right: 20px;

    &:before {
        content: '';
        background : {
            image: url('/images/path-icon.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        width: 24px;
        height: 24px;
        margin-right: 13px;
    }
}

.main__breadcrumb {
    display: flex;
    
    &:after {
        content: '/';
        margin: 0 10px;
    }

    &:last-child:after {
        display: none;
    }
}

.main__breadcrumb-link {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: $path-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.main__burger-input {
    display: none;

    &:checked {

        ~ .main__burger .main__burger-text {
            opacity: 0;
        }

        ~ .main__nav {
            display: block;
        }
    }
}

.main__burger {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    color: $footer-fill;
    text-transform: uppercase;
    cursor: pointer;
}

.main__burger-text {
    margin-right: 7px;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.main__burger-icon {
    background : {
        image: url('/images/burger.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 28px;
    height: 17px;
}

.main__nav {
    display: none;
    position: absolute;
    z-index: 2;
    top: -30px;
    right: -30px;

    @media screen and (max-width: 39.9375em) {
        top: 0;
        right: 0;
    }
}

.main__nav-ul {
    background-color: $menu-background;
    padding: 80px 30px 30px;
    box-sizing: border-box;
    height: 440px;
    overflow-y: auto;
    margin: 0;

    @media screen and (max-width: 39.9375em) {
        padding: 50px 0 30px 10px;
        height: initial;
    }
}

.main__nav-li {
    // position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;

    @media screen and (max-width: 39.9375em) {
        flex-wrap: wrap;
    }
}

.main__nav-link {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: $menu-text-color;
    text-decoration: none;
    margin-bottom: 16px;

    &:hover {
        color: $footer-fill;
    }
}

.main__submenu-toggler {
    border: none;
    color: $footer-fill;
    margin-right: 1em;
    cursor: pointer;
    background : {
        color: transparent;
        image: url('/images/submenu-toggler.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 1em;
    height: 1em;
}

.main__nav-li_closed > .main__nav-ul_level_two {
    display: none;
}

.main__nav-li_opened > .main__nav-ul_level_two {
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    padding: 30px;
    max-width: 462px;
    width: 100%;
    background-color: #f3f3f3;

    @media screen and (max-width: 39.9375em) {
        position: static;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
    }
}

.main__nav-li_type_h {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    color: $footer-fill;
    text-transform: uppercase;
    margin-bottom: 24px;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.main__h {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 20px;
    color: $footer-fill;
    text-transform: uppercase;
    margin-bottom: 27px;
}

.main__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.main__media_side_left {
    max-width: 516px;
    width: 100%;
    flex-shrink: 0;
}

.main__media_side_right {
    max-width: 425px;
    width: 100%;
    flex-shrink: 0;
}

.main__h_with_arrow {
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:after {
        content: "";
        background : {
            image: url('/images/h-arrow.png');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.3em;
    }

    &:hover {
        text-decoration: underline;
    }
}

.pages {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        display: block;
        margin: 0 5px;
    }
}

.page:first-child {
    font-size: 0;
    background : {
        image: url('/images/page-prev.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 20px;
    height: 20px;
}

.page:last-child {
    font-size: 0;
    background : {
        image: url('/images/page-next.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 20px;
    height: 20px;
}
